<script setup>
defineProps({
  cardClassNames: {
    type: String,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['close-bottom-sheet']);

const $style = useCssModule();
const showContent = ref(false);

const backgroundClassNames = [
  'tw-fixed tw-inset-0 tw-z-50',
  $style.background,
];

const containerClass = computed(() => {
  return [
    'tw-absolute',
    'tw-z-20 tw--bottom-4 tw-left-0 tw-right-0',
    'tw-w-full tw-overflow-hidden',
    $style.container,
  ];
});

const handleAfterEnter = () => {
  showContent.value = true;
};

const handleCloseButtonClick = () => {
  showContent.value = false;
};
</script>

<template>
  <transition
    enter-active-class="fade-enter-transition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
    @after-enter="handleAfterEnter"
  >
    <div
      v-if="visible"
      :class="backgroundClassNames"
    >
      <transition
        :enter-active-class="$style.slideTransition"
        :enter-from-class="$style.slideDown"
        :leave-active-class="$style.slideTransition"
        :leave-to-class="$style.slideDown"
        @after-leave="$emit('close-bottom-sheet')"
      >
        <div
          v-if="showContent"
          :class="containerClass"
        >
          <Card
            :card-class-names="cardClassNames"
            :has-body-padding-small="true"
            :is-full-height="true"
            :show-close-button="true"
            @close-button-click="handleCloseButtonClick"
          >
            <template #body>
              <slot name="content" />
            </template>
          </Card>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style module>
.background {
  backdrop-filter: blur(1px);
  background-color: rgb(0 0 0 / 50%);
  grid-template-columns: 90vw;
}

.container {
  height: var(--visible-height, 23rem);
  opacity: 1;
}

@media (min-width: 768px) {
  .container {
    margin: 0 auto;
    max-width: 95vw;
  }
}

.slideTransition {
  overflow: hidden;
  transition-duration: 250ms;
  transition-property: height, opacity;
  transition-timing-function: ease-in-out;
}

.slideDown {
  height: 0;
  opacity: 0;
}
</style>
